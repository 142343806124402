// SAMPLE IMPORTS
// import 'select2';
// import 'select2/dist/css/select2.css';

$(document).ready(function(){
  $( "body" ).on( "click", '[type="submit"]', formValidation );

  init_recommendations_slick();
  init_collapsed_nav_bar();

  $(window).scroll(function() {
    if ($(window).scrollTop() > 0) {
      $(".navbar-top-2").addClass("box-shadow");
    } else {
      $(".navbar-top-2").removeClass("box-shadow");
    }
  });
});

var formValidation = function() {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = $('.needs-validation');
  // Loop over them and prevent submission
  if(forms.length > 0) {
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('submit', function(event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }
}

function accepted_terms(form) {
  if(checkbox.length > 0) {
    console.log(checkbox[0].checked);
    return !checkbox[0].checked
  } else {
    return false
  }
}

/**
Slick carousel for reviews section
**/
function init_recommendations_slick(){

  //init carousel
  $('.recommendations-slick').not('.slick-initialized').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    ]
  });

  // Custom carousel navigation
  $('.slick-arrow-prev').click(function(e){
    e.preventDefault();
    $('.recommendations-slick').slick('slickPrev');
  });
  $('.slick-arrow-next').click(function(e){
    e.preventDefault();
    $('.recommendations-slick').slick('slickNext');
  });
}


function init_collapsed_nav_bar(){

  $('#navbarTogglerCollapsed').click(function(e){
    e.preventDefault();
    $('#navbarTop2Opened').show();
    $('#navbarTop2').hide();
  });
  $('#navbarTogglerExpanded').click(function(e){
    e.preventDefault();
    $('#navbarTop2Opened').hide();
  });
}
